"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sources = void 0;
const arxiv_1 = require("./arxiv");
const ar5iv_1 = require("./ar5iv");
const crossRef_1 = require("./crossRef");
const openReview_1 = require("./openReview");
const PaperShelf_1 = require("./PaperShelf");
const semanticScholar_1 = require("./semanticScholar");
__exportStar(require("./arxiv"), exports);
__exportStar(require("./ar5iv"), exports);
__exportStar(require("./base"), exports);
__exportStar(require("./crossRef"), exports);
__exportStar(require("./openReview"), exports);
__exportStar(require("./PaperShelf"), exports);
__exportStar(require("./semanticScholar"), exports);
exports.Sources = [
    {
        key: 'paperShelf',
        name: 'PaperShelf',
        url: 'https://papershelf.app',
        cls: PaperShelf_1.PaperShelf,
        canFetch: true,
        canSearch: false,
        deselectable: false,
    },
    {
        key: 'arxiv',
        name: 'arXiv',
        url: 'https://arxiv.org',
        cls: arxiv_1.Arxiv,
        canFetch: true,
        canSearch: true,
        deselectable: false,
    },
    {
        key: 'ar5iv',
        name: 'ar5iv',
        url: 'https://ar5iv.labs.arxiv.org',
        cls: ar5iv_1.Ar5iv,
        canFetch: true,
        canSearch: false,
        deselectable: true,
    },
    {
        key: 'semanticScholar',
        name: 'Semantic Scholar',
        url: 'https://www.semanticscholar.org',
        cls: semanticScholar_1.SemanticScholar,
        canFetch: true,
        canSearch: true,
        deselectable: true,
    },
    {
        key: 'crossRef',
        name: 'CrossRef',
        url: 'https://www.crossref.org',
        cls: crossRef_1.CrossRef,
        canFetch: true,
        canSearch: false,
        deselectable: true,
    },
    {
        key: 'openReview',
        name: 'OpenReview',
        url: 'https://openreview.net',
        cls: openReview_1.OpenReview,
        canFetch: true,
        canSearch: false,
        deselectable: true,
    },
];
