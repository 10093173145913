"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ar5iv = void 0;
const node_html_parser_1 = require("node-html-parser");
const arxiv_1 = require("./arxiv");
// const getCategoryName = (id: string) => {
//   const res = (
//     categories as {
//       id: string;
//       name: string;
//     }[]
//   ).filter((c) => c.id === id);
//   if (res.length === 1) {
//     return res[0].name.replaceAll(' ', '-').toLowerCase();
//   }
//   return id;
// };
exports.Ar5iv = {
    source: 'ar5iv',
    canSearch: false,
    search: (searchQuery, start = 0, maxResults = 10) => __awaiter(void 0, void 0, void 0, function* () { return ([]); }),
    fetch: (paperQuery) => __awaiter(void 0, void 0, void 0, function* () {
        if (paperQuery.url) {
            const arxivId = (0, arxiv_1.getArxivIdFromUrl)(paperQuery.url) || undefined;
            return exports.Ar5iv.fetch({ arxivId });
        }
        else if (paperQuery.arxivId) {
            const res = yield fetch(`https://ar5iv.labs.arxiv.org/html/${paperQuery.arxivId}`);
            const htmlContent = yield res.text();
            const root = (0, node_html_parser_1.parse)(htmlContent).querySelector('body');
            if (!root)
                return { textContent: undefined };
            root.querySelectorAll('script').forEach((e) => e.remove());
            const textContent = root.textContent.replace(/\n\s*\n/g, '\n');
            return {
                textContent,
            };
        }
        throw Error('Paper not found.');
    }),
};
