"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenReview = void 0;
const utils_1 = require("./utils");
// const getCategoryName = (id: string) => {
//   const res = (
//     categories as {
//       id: string;
//       name: string;
//     }[]
//   ).filter((c) => c.id === id);
//   if (res.length === 1) {
//     return res[0].name.replaceAll(' ', '-').toLowerCase();
//   }
//   return id;
// };
exports.OpenReview = {
    source: 'OpenReview',
    canSearch: false,
    search: (searchQuery, start = 0, maxResults = 10) => __awaiter(void 0, void 0, void 0, function* () {
        const normalize = (t) => t.toLowerCase().replace(/\W/g, ' ');
        const res = yield (yield fetch(`https://api.openreview.net/notes/search?${new URLSearchParams({
            query: normalize(searchQuery),
            limit: maxResults.toString(),
            offset: start.toString(),
        }).toString()}`)).json();
        const items = res.notes;
        return items.map((json) => {
            const data = json;
            return Object.assign(Object.assign({}, data), { title: data.content.title });
        });
    }),
    fetch: (paperQuery) => __awaiter(void 0, void 0, void 0, function* () {
        if (paperQuery.title && paperQuery.authors) {
            const res = yield exports.OpenReview.search(paperQuery.title, 0, 5);
            for (const p of res) {
                if ((0, utils_1.comparePaperWithQuery)(p, paperQuery))
                    return p;
            }
            throw Error('Paper not found.');
        }
        else {
            throw Error(`Query ${JSON.stringify(paperQuery)} is invalid or not supported.`);
        }
    }),
};
