"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.comparePaperTitle = exports.fetchWithTimeout = exports.comparePaperWithQuery = exports.comparePapers = void 0;
const comparePapers = (p1, p2) => {
    var _a, _b, _c, _d;
    if (((_a = p1.title) === null || _a === void 0 ? void 0 : _a.replace(/\W/g, '').toLowerCase()) !==
        ((_b = p2.title) === null || _b === void 0 ? void 0 : _b.replace(/\W/g, '').toLowerCase())) {
        return false;
    }
    if (((_c = p1.authorNames) === null || _c === void 0 ? void 0 : _c.length) !==
        ((_d = (p2.authors || p2.authorNames)) === null || _d === void 0 ? void 0 : _d.length)) {
        return false;
    }
    return true;
};
exports.comparePapers = comparePapers;
const comparePaperWithQuery = (p, q) => {
    var _a;
    if (q.title &&
        ((_a = p.title) === null || _a === void 0 ? void 0 : _a.replace(/\W/g, '').toLowerCase()) !==
            q.title.replace(/\W/g, '').toLowerCase()) {
        return false;
    }
    return true;
};
exports.comparePaperWithQuery = comparePaperWithQuery;
const fetchWithTimeout = (input, _timeout = 3000) => __awaiter(void 0, void 0, void 0, function* () {
    return Promise.race([
        fetch(input),
        // TODO: temporarily disable timeout since it causes issues with tests
        // new Promise((_, reject) =>
        //   setTimeout(() => reject(new Error('Timeout')), timeout),
        // ),
    ]).then();
});
exports.fetchWithTimeout = fetchWithTimeout;
/**
 * @param t1 - title 1
 * @param t2 - title 2
 * @returns - `true` if two papers are the same
 */
function comparePaperTitle(t1, t2) {
    const normalize = (t) => t.toLowerCase().replace(/\W/g, '');
    return normalize(t1) === normalize(t2);
}
exports.comparePaperTitle = comparePaperTitle;
