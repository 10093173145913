"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaperShelf = void 0;
exports.PaperShelf = {
    source: 'PaperShelf',
    canSearch: false,
    search: () => __awaiter(void 0, void 0, void 0, function* () { return []; }),
    fetch: (paperQuery) => __awaiter(void 0, void 0, void 0, function* () {
        if (!paperQuery.paperShelfId)
            throw Error('Paper not found.');
        const response = yield fetch('https://papershelf-node.azurewebsites.net/api/GetPublicPaper?id=' +
            paperQuery.paperShelfId);
        if (response.status === 404)
            throw Error('Paper not found.');
        return (Object.assign(Object.assign({}, (yield response.json())), { id: paperQuery.paperShelfId }));
    }),
};
