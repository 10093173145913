"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrossRef = void 0;
const lodash_1 = __importStar(require("lodash"));
const utils_1 = require("./utils");
const deepMapKeys = (obj) => {
    if (!(0, lodash_1.isObject)(obj))
        return obj;
    if ((0, lodash_1.isArray)(obj))
        return obj.map((d) => deepMapKeys(d));
    return Object.fromEntries(Object.entries(obj).map(([key, val]) => [
        lodash_1.default.camelCase(key),
        deepMapKeys(val),
    ]));
};
const mapJSONtoObject = (data) => ({
    title: data.title ? data.title[0] : '',
    containerTitle: data.containerTitle,
    publisher: data.publisher,
    member: data.member,
    referenceCount: data.referenceCount,
    citationCount: data.isReferencedByCount,
    type: data.type,
    event: data.event ?
        {
            name: data.event.name,
            location: data.event.location,
            start: data.event.start && data.event.start.dataParts,
            end: data.event.end && data.event.end.dataParts,
        } :
        undefined,
    authors: data.author ?
        data.author.map((a) => ({
            given: a.given,
            family: a.family,
            sequence: a.sequence,
            affliation: a.affliation,
        })) :
        undefined,
    deposited: data.deposited.dateTime,
    created: data.created.dateTime,
    indexed: data.indexed.dateTime,
    link: [
        ...(data.link ? data.link.map((d) => ({ url: d.url })) : []),
        ...(data.resource.primary.URL || []),
    ],
    url: data.url,
    language: data.language,
    subjects: data.subject,
    error: null,
});
exports.CrossRef = {
    source: 'CrossRef',
    canSearch: false,
    search: (searchQuery, start = 0, maxResults = 10) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield (yield (0, utils_1.fetchWithTimeout)(`https://api.crossref.org/works?${new URLSearchParams({
            query: searchQuery,
            offset: start.toString(),
            rows: maxResults.toString(),
        }).toString()}`)).json();
        if (res.status !== 'ok')
            throw Error('Failed to retrieved data.');
        const items = deepMapKeys(res.message.items);
        return items.map((data) => mapJSONtoObject(data));
    }),
    fetch: (paperQuery) => __awaiter(void 0, void 0, void 0, function* () {
        if (paperQuery.doi) {
            const res = yield (yield (0, utils_1.fetchWithTimeout)(`https://api.crossref.org/works/${paperQuery.doi}`)).json();
            if (res.status !== 'ok')
                return null;
            const data = deepMapKeys(res.message);
            return mapJSONtoObject(data);
        }
        else if (paperQuery.title && paperQuery.authors) {
            const res = yield exports.CrossRef.search(paperQuery.title, 0, 5);
            for (const p of res) {
                if ((0, utils_1.comparePaperWithQuery)(p, paperQuery))
                    return p;
            }
            throw Error('Paper not found.');
        }
        else {
            throw Error(`Query ${JSON.stringify(paperQuery)} is invalid or not supported.`);
        }
    }),
};
