"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SemanticScholar = void 0;
const utils_1 = require("./utils");
const getAuthors = (authors) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Promise.all(authors === null || authors === void 0 ? void 0 : authors.map((a) => __awaiter(void 0, void 0, void 0, function* () {
        if (a.authorId === null) {
            return {
                authorId: a.authorId,
                name: a.name,
            };
        }
        try {
            const response = yield fetch(`https://api.semanticscholar.org/graph/v1/author/${a.authorId}?fields=affiliations,authorId,name,url`);
            return yield response.json();
        }
        catch (e) {
            return a;
        }
    })));
});
/**
 * get semantic scholar paper's identifier
 * @param paperQuery - PaperQuery
 */
function getIdentifier(paperQuery) {
    if (paperQuery.arxivId)
        return `arxiv:${paperQuery.arxivId}`;
    else if (paperQuery.semanticScholarId)
        return paperQuery.semanticScholarId;
    return null;
}
exports.SemanticScholar = {
    source: 'Semantic Scholar',
    canSearch: true,
    fetch: (paperQuery) => __awaiter(void 0, void 0, void 0, function* () {
        const id = getIdentifier(paperQuery);
        const fields = ['paperId', 'externalIds', 'url', 'title', 'abstract',
            'venue', 'year', 'referenceCount', 'citationCount',
            'influentialCitationCount', 'isOpenAccess', 'fieldsOfStudy',
            's2FieldsOfStudy', 'openAccessPdf', 'authors', 'tldr'].join(',');
        const endpoint = 'https://api.semanticscholar.org/graph/v1/paper';
        console.log(id, paperQuery);
        if (id) {
            const response = yield fetch(`${endpoint}/${id}?fields=${fields}`);
            if (response.status !== 200) {
                throw Error(`Status code: ${response.status}`);
            }
            const data = yield response.json();
            return Object.assign(Object.assign({}, data), { authors: data.authors });
        }
        else if (!!paperQuery.title && !!paperQuery.authors) {
            const fields = ['paperId', 'externalIds', 'url', 'title', 'abstract',
                'venue', 'year', 'referenceCount', 'citationCount',
                'influentialCitationCount', 'isOpenAccess', 'fieldsOfStudy',
                's2FieldsOfStudy', 'openAccessPdf', 'authors'].join(',');
            const response = yield fetch(`${endpoint}/search?query=${paperQuery.title}&fields=${fields}`);
            if (response.status !== 200) {
                throw Error(`Status code: ${response.status}`);
            }
            const data = yield response.json();
            for (const p of data.data) {
                const ssPaper = {
                    title: p.title,
                    authorNames: p.authors.map((a) => a.name)
                };
                if ((0, utils_1.comparePapers)(ssPaper, paperQuery)) {
                    return Object.assign(Object.assign({}, p), { authors: p.authors });
                }
            }
        }
        throw Error('Paper not found.');
    }),
    search: (searchQuery, offset, limit) => __awaiter(void 0, void 0, void 0, function* () {
        const endpoint = 'https://api.semanticscholar.org/graph/v1/paper/search';
        const normalize = (t) => t.toLowerCase().replace(/\W/g, ' ');
        const urlSearchParams = new URLSearchParams({
            query: normalize(searchQuery),
            offset: offset.toString(),
            limit: limit.toString(),
            fields: 'externalIds,url,title,venue,year,citationCount,authors,abstract',
        }).toString();
        const response = yield (yield fetch(`${endpoint}?${urlSearchParams}`)).json();
        const papers = yield Promise.all(response.data.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (d) => __awaiter(void 0, void 0, void 0, function* () {
            return ({
                title: d.title,
                paperId: d.paperId,
                externalIds: d.externalIds,
                url: d.url,
                venue: d.venue,
                year: d.year,
                citationCount: d.citationCount,
                authors: d.authors,
                abstract: d.abstract,
            });
        })));
        return papers;
    }),
    getReferencePapers: (paperQuery) => __awaiter(void 0, void 0, void 0, function* () {
        const id = getIdentifier(paperQuery);
        if (id) {
            const response = yield fetch(`https://api.semanticscholar.org/graph/v1/paper/${id}?fields=` +
                (['title', 'authors', 'venue', 'year', 'externalIds', 'citationCount']
                    .map((f) => `references.${f}`)).join(','));
            if (response.status !== 200) {
                throw Error(`Status code: ${response.status}`);
            }
            const data = yield response.json();
            const sps = yield Promise.all(data.references.map((it) => __awaiter(void 0, void 0, void 0, function* () {
                return (Object.assign({}, it));
            })));
            return sps.filter((sp) => !!sp.title && !!sp.authors.length);
        }
        throw Error('Paper cannot be identified.');
    }),
    getCitationPapers: (paperQuery) => __awaiter(void 0, void 0, void 0, function* () {
        const id = getIdentifier(paperQuery);
        if (id) {
            const response = yield fetch(`https://api.semanticscholar.org/graph/v1/paper/${id}?fields=` +
                (['title', 'authors', 'venue', 'year', 'externalIds', 'citationCount']
                    .map((f) => `citations.${f}`)).join(','));
            if (response.status !== 200) {
                throw Error(`Status code: ${response.status}`);
            }
            const data = yield response.json();
            const sps = yield Promise.all(data.citations.map((it) => __awaiter(void 0, void 0, void 0, function* () {
                return (Object.assign({}, it));
            })));
            return sps.filter((sp) => !!sp.title && !!sp.authors.length);
        }
        throw Error('Paper cannot be identified.');
    }),
};
