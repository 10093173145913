"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPdfUrlFromArxivId = exports.Arxiv = exports.getArxivIdFromUrl = void 0;
const fast_xml_parser_1 = require("fast-xml-parser");
const utils_1 = require("./utils");
// const getCategoryName = (id: string) => {
//   const res = (
//     categories as {
//       id: string;
//       name: string;
//     }[]
//   ).filter((c) => c.id === id);
//   if (res.length === 1) {
//     return res[0].name.replaceAll(' ', '-').toLowerCase();
//   }
//   return id;
// };
/**
 * @param url - url string
 * @returns arxiv id from the url
 */
function getArxivIdFromUrl(url) {
    const pattern = 
    // eslint-disable-next-line max-len
    /^https?:\/\/arxiv.org\/(?:abs\/([0-9]+\.[0-9]+)(?:v[0-9]+)?|pdf\/([0-9]+\.[0-9]+)(?:v[0-9]+)?\.pdf)$/;
    if (pattern.test(url)) {
        const matches = url.match(pattern);
        return matches ? matches[1] || matches[2] : null;
    }
    return null;
}
exports.getArxivIdFromUrl = getArxivIdFromUrl;
exports.Arxiv = {
    source: 'arXiv',
    canSearch: true,
    search: (searchQuery, start = 0, maxResults = 10) => __awaiter(void 0, void 0, void 0, function* () {
        const arxivId = getArxivIdFromUrl(searchQuery) ||
            (searchQuery.startsWith('arxiv:') ?
                searchQuery.replace('arxiv:', '') :
                null);
        const normalize = (t) => t.toLowerCase().replace(/\W/g, ' ');
        const getPdfUrl = (id) => `${id.replace('abs', 'pdf')}.pdf`;
        const response = yield fetch(`https://export.arxiv.org/api/query?${new URLSearchParams(Object.assign(Object.assign({}, (arxivId ?
            { id_list: arxivId } :
            { search_query: normalize(searchQuery) })), { start: start.toString(), max_results: maxResults.toString(), sortBy: 'relevance' })).toString()}`);
        const rawXmlContent = yield response.text();
        const parser = new fast_xml_parser_1.XMLParser({
            ignoreAttributes: false,
            parseAttributeValue: true,
        });
        const data = parser.parse(rawXmlContent);
        const entries = data.feed.entry ?
            Array.isArray(data.feed.entry) ?
                data.feed.entry :
                [data.feed.entry] :
            [];
        return entries.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e) => ({
            id: getArxivIdFromUrl(e.id),
            pdfUrl: getPdfUrl(e.id),
            htmlUrl: e.id.replace('arxiv', 'ar5iv'),
            title: e.title,
            abstract: e.summary.trim().split('\n').join(' '),
            comment: 'arxiv:comment' in e ? e['arxiv:comment']['#text'] : undefined,
            journalRef: 'arxiv:journal_ref' in e ?
                e['arxiv:journal_ref']['#text'] : undefined,
            updated: new Date(e.updated),
            published: new Date(e.published),
            authors: (Array.isArray(e.author) ? e.author : [e.author]).map((author) => author.name),
            categories: (Array.isArray(e.category) ?
                e.category : [e.category]).map((cat) => {
                return cat['@_term'];
            }),
        }));
    }),
    fetch: (paperQuery) => __awaiter(void 0, void 0, void 0, function* () {
        if (paperQuery.url) {
            const arxivId = getArxivIdFromUrl(paperQuery.url) || undefined;
            return exports.Arxiv.fetch({ arxivId });
        }
        else if (paperQuery.arxivId) {
            const res = yield exports.Arxiv.search('arxiv:' + paperQuery.arxivId, 0, 10);
            if (res.length === 1) {
                const arxivPaper = res[0];
                return arxivPaper;
            }
        }
        else if (paperQuery.title) {
            const res = yield exports.Arxiv.search(paperQuery.title, 0, 10);
            for (const arxivPaper of res) {
                if ((0, utils_1.comparePaperTitle)(paperQuery.title || '', arxivPaper.title || '')) {
                    return arxivPaper;
                }
            }
        }
        throw Error('Paper not found.');
    }),
};
const getPdfUrlFromArxivId = (id) => {
    return `https://arxiv.org/pdf/${id}.pdf`;
};
exports.getPdfUrlFromArxivId = getPdfUrlFromArxivId;
